$primary-color: #878787;
$secondary-color: #fff;

@import 'nav';

/* FONTS */

@font-face {
  font-family: "dm-sans-med";
  src: url("../assets/fonts/DMSans-Medium.woff2") format("woff2"),
    url("../assets/fonts/DMSans-Medium.woff2") format("ttf"),
    url("../assets/fonts/DMSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "dm-sans-reg";
  src: url("../assets/fonts/DMSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/DMSans-Regular.woff") format("ttf"),
    url("../assets/fonts/DMSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//reponsive 
.desk-only {
  display: block;

  @media screen and (orientation: landscape) and (max-device-width: 812px) {
      display: none;
  }
}

.mobile-only {
  display: none;

  @media screen and (orientation: landscape) and (max-device-width: 812px) {
      display: block;
  }
}

h1 {
  font-family: "dm-sans-med";
}

body {
  font-family: "dm-sans-med";
  background: $primary-color;
}

.canvas-container {
  display: block;
  width: 100vw;
  height: 100vh;
}

a {
  color: #080808!important;
}

.canvas-container:hover {
  cursor: url("../assets/icons/cursor.png"), auto;
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}

/* bottom nav */
.bottom-nav {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.bottom-nav__sub {
  display: none;
}

.bottom-nav__sub > *:not(:last-child) {
  margin-right: 1rem;
}

/* buttons */
.plain-round-button {
  width: auto;
  background: #e4dff2;
  border: none;
  border-radius: 25px;
  padding: 10px 18px;
  transition: all 0.25s ease-in-out;
}

.plain-round-button:hover {
  outline: none;
  border: none;
  color: #e4dff2;
  background: black;
}

.plain-round-button:focus {
  outline: none;
  border: none;
}

.toggle-btn.active {
  outline: none;
  border: none;
  color: #e4dff2;
  background: black;
}

/* /phone rotation */

.main {
  display: block;
}

@media screen and (orientation: portrait) {
  .main {
    display: none;
  }
}

.phone-rotation {
  display: none;
  width: 100vw;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (orientation: portrait) {
  .phone-rotation {
    display: block;
  }
}

.phone-rotation svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

/* close */
.close-container {
  padding: 12px;
  position: absolute;
  //border: 1px solid $primary-color;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &--white {

    //border: 1px solid $secondary-color;

    .close-ico {
      display: inline-block;
      width: 18px;
      height: 19px;
      background-image: url(../assets/icons/close-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.close-ico { 
  display: block;
  width: 18px;
  height: 19px;
  background-image: url(../assets/icons/close-black.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.fullscreen-ico { 
  display: block;
  width: 15px;
  height: 15px;
  background-image: url(../assets/icons/fullscreen-black.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  svg {
    fill: $primary-color;
    width: 15px;
    height: 15px;
  }

  &--white {
    background-image: url(../assets/icons/fullscreen-white.svg);

    svg {
      fill: $secondary-color;
      width: 15px;
      height: 15px;
    }
  }
}

.close-ico:hover {
  cursor: pointer;
}

.close-ico svg {
  fill: $primary-color;
  width: 18px;
  height: 18px;
}

.popup {
  position: relative;
}

.close-popup {
  position: absolute;
  right: 8px;
  top: 5px;
  border: none;
  border-radius: 0;
  background: transparent;
  transition: none;
  transition: all 0.25s ease-in-out;
}

.close-popup:hover {
  border: none;
  outline: none;
  background: transparent;
}

.close-popup:focus {
  border: none;
  outline: none;
  background: transparent;
}

.popup__content {
  display: none;
  position: fixed;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  padding: 3rem;
  border-radius: 20px;
  min-width: 85vw;
  height: 73vh;
  overflow: hidden;
}

.popup__content--visible {
  display: block;
}

@media screen and (orientation: landscape) and (max-device-width: 812px) {
  .popup__content {
    min-width: 85vw;
    padding: 1.5rem 3rem;
    bottom: 22%;
    height: 70vh;
  }
}

.popup__inner {
  height: 100%;
  overflow-y: scroll;
}

.credits__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.credits__list li {
  font-size: 20px;
  color: white;
  line-height: 30px;
}

.credits__list li:not(:last-child) {
  margin-bottom: 10px;
}

@media screen and (orientation: landscape) and (max-device-width: 812px) {
  .credits__list li {
    font-size: 18px;
  }
}

/* //loader */
.loader{
  background: transparent;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  width: 51px;
  height: 51px;
  margin: 0 auto;
  border: 5px solid transparent;
  border-top: 5px solid #5c5c5c;
  border-radius: 50%;
  animation: rotate 0.67s infinite linear;
}

.loader.active {
  display: block;
  transform: translate(-50%, -50%);
}

@keyframes rotate{
  from{transform: rotate(0)}
  to{transform: rotate(360deg)}
}

.rooms-nav {
  position: fixed;
  left: 40px;
  bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
  background: chartreuse;
  padding: 3px;
  list-style-type: none;
}
.rooms-nav__link {
  color: purple;
  font-size: 16px;
  text-decoration: underline;
  padding: 10px;
}

.rooms-nav__link:hover {
  cursor: pointer;
}

.arrow-white {
  background-image: url(../assets/icons/arrow.svg);
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;

  &--right {
    transform: rotate(90deg);
  }
  &--left {
    transform: rotate(-90deg);
  }
  &--bottom {
    transform: rotate(180deg);
  }
}

.arrows-white {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  
  .decrease-pov {
    display: block;
    width: 8px;
    height: 13px;
    transform: rotate(90deg);

    &:hover {
      cursor: pointer;
    }
  }

  .increase-pov {
    display: block;
    width: 8px;
    height: 13px;
    transform: rotate(-90deg);

    &:hover {
      cursor: pointer;
    }
  }
}

.stairs-icon {
  background-image: url(../assets/icons/stairs.svg);
  width: 13px;
  height: 13px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.informations-icon {
  background-image: url(../assets/icons/informations.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &--white {
    background-image: url(../assets/icons/information-white.svg);
  }
}

.credits-icon {
  background-image: url(../assets/icons/credits.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  &--white {
    background-image: url(../assets/icons/credits-white.svg);
  }
}

.download-icon {
  background-image: url(../assets/icons/download.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  &--white {
    background-image: url(../assets/icons/download-white.svg);
  }
}


.label-room, .label-floor{
  pointer-events: none !important;
}