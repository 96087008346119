.top-nav {
  grid-template-columns: 1fr 2fr 70px 70px 1fr 1fr 1fr;
  grid-auto-flow: column;
  gap: 0;
  width: 100vw;
  font-family: dm-sans-med;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .top-nav {
    grid-template-columns: 1fr 2fr 70px 70px .5fr .5fr .5fr 0fr;
  }
}

.top-nav__item {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background: #878787;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  font-size: 12px;
  display: flex;
  position: relative;
}

.top-nav__item--white {
  color: #878787;
  background: #fff;
  border: 1px solid;
}

.top-nav__item--black {
  color: #fff;
  background: #878787;
  border: 1px solid;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .top-nav__item--mobile-lg {
    flex-flow: row;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    display: flex !important;
  }
}

.top-nav__item:first-child {
  border-right: 1px solid #fff;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .top-nav__item span:first-child {
    margin-right: 10px;
  }
}

.top-nav__item:hover {
  cursor: pointer;
}

.top-nav__sub {
  width: calc(100% + 1px);
  margin: 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.top-nav__sub--is-active {
  display: block;
}

.top-nav__sub .top-nav__item {
  border: 1px solid;
  justify-content: left;
}

.top-nav__sub .top-nav__item:hover {
  color: #878787;
  background: #fff;
  border: 1px solid;
  border-left: #878787;
  border-right: #878787;
}

.top-nav__sub .top-nav__item--white:hover {
  color: #fff;
  background: #878787;
}

.top-nav__sub .top-nav__item--black:hover {
  color: #878787;
  background: #fff;
}

.in-room-nav {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.credits {
  margin: 0;
  display: inline-block;
  position: relative;
}

.credits__container {
  z-index: 1;
  background: #878787;
  height: calc(100vh - 36px);
  margin: 0;
  display: none;
  position: fixed;
  top: 36px;
  right: 0;
  overflow-y: scroll;
}

.credits__container.is-active {
  z-index: 2;
  display: block;
}

.credits__content {
  text-align: left;
  color: #fff;
  text-transform: initial;
  margin: 0;
  padding: 25px 40px 25px 25px;
  font-family: dm-sans-reg;
  font-size: 14px;
  list-style-type: none;
}

.credits__content:hover {
  cursor: auto;
}

.credits__item {
  flex-flow: row;
  justify-content: flex-start;
  display: flex;
}

.credits__item:not(:last-child) {
  margin-bottom: 10px;
}

.credits__text {
  margin: 0;
}

.site-infos__content {
  text-align: left;
  color: #878787;
  text-transform: initial;
  z-index: 1;
  background: #fff;
  width: auto;
  height: calc(100vh - 28px);
  padding: 25px;
  font-family: dm-sans-reg;
  font-size: 14px;
  display: none;
  position: fixed;
  top: 36px;
  bottom: 0;
  right: 0;
  overflow: scroll;
}

.site-infos__content:hover {
  cursor: auto;
}

.site-infos__content.is-active {
  z-index: 2;
  border-left: 1px solid;
  display: block;
}

.site-infos__content a {
  font-style: italic;
  color: #878787 !important;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .site-infos__content {
    padding: 15px 35px 15px 15px;
  }
}

.site-infos__txt {
  padding-right: 20px;
}

.screenshot {
  margin: 0;
  position: relative;
}

.screenshot .blink-active {
  animation: 1s linear infinite blink;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: .5;
  }

  65% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.screenshot__container {
  z-index: 1;
  background: #fff;
  border-left: 1px solid #878787;
  height: calc(100vh - 36px);
  margin: 0;
  display: none;
  position: fixed;
  top: 36px;
  right: 0;
  overflow-y: scroll;
}

.screenshot__container.is-active {
  z-index: 2;
  display: block;
}

.screenshot__content, .screenshot__content_nft {
  text-align: left;
  color: #878787;
  text-transform: initial;
  text-transform: initial;
  margin: 0;
  padding: 25px;
  font-family: dm-sans-reg;
  font-size: 14px;
  list-style-type: none;
}

.screenshot-valid {
  color: #fff;
  text-transform: uppercase;
  background: #878787;
  width: fit-content;
  padding: 8px;
}

.screenshot-valid:hover {
  cursor: pointer;
  color: #878787;
  background: #fff;
}

.screenshot-valid b {
  cursor: pointer;
  font-weight: 100;
}

.screenshot__item {
  flex-flow: row;
  justify-content: flex-start;
  display: flex;
}

.screenshot__item:not(:last-child) {
  margin-bottom: 10px;
}

.screenshot__text {
  margin: 0;
}

.lang-toggle {
  border-top: 1px solid #878787;
  border-bottom: 1px solid #878787;
  align-items: stretch;
  padding: 0;
}

.lang-toggle button {
  appearance: none;
  text-transform: uppercase;
  background: none;
  border: none;
  outline: none;
  padding: 0 15px;
  font-family: dm-sans-med;
  font-size: 12px;
}

.lang-toggle button:first-child {
  color: #fff;
  background: #878787;
}

.lang-toggle button:last-child {
  color: #878787;
  background: #fff;
  padding: 0 14px;
}

.lang-toggle button:first-child:after {
  width: 5px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.lang-toggle button:focus {
  outline: 0;
}

@font-face {
  font-family: dm-sans-med;
  src: url("DMSans-Medium.deb7d1bc.woff2") format("woff2"), url("DMSans-Medium.deb7d1bc.woff2") format("ttf"), url("DMSans-Medium.2e29f074.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: dm-sans-reg;
  src: url("DMSans-Regular.1353335f.woff2") format("woff2"), url("DMSans-Regular.a79c22b6.woff") format("ttf"), url("DMSans-Regular.a79c22b6.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.desk-only {
  display: block;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .desk-only {
    display: none;
  }
}

.mobile-only {
  display: none;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .mobile-only {
    display: block;
  }
}

h1 {
  font-family: dm-sans-med;
}

body {
  background: #878787;
  font-family: dm-sans-med;
}

.canvas-container {
  width: 100vw;
  height: 100vh;
  display: block;
}

a {
  color: #080808 !important;
}

.canvas-container:hover {
  cursor: url("cursor.d493a212.png"), auto;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.bottom-nav {
  flex-flow: row;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.bottom-nav__sub {
  display: none;
}

.bottom-nav__sub > :not(:last-child) {
  margin-right: 1rem;
}

.plain-round-button {
  background: #e4dff2;
  border: none;
  border-radius: 25px;
  width: auto;
  padding: 10px 18px;
  transition: all .25s ease-in-out;
}

.plain-round-button:hover {
  color: #e4dff2;
  background: #000;
  border: none;
  outline: none;
}

.plain-round-button:focus {
  border: none;
  outline: none;
}

.toggle-btn.active {
  color: #e4dff2;
  background: #000;
  border: none;
  outline: none;
}

.main {
  display: block;
}

@media screen and (orientation: portrait) {
  .main {
    display: none;
  }
}

.phone-rotation {
  background: #fff;
  width: 100vw;
  height: 100vh;
  display: none;
  position: absolute;
  inset: 0;
}

@media screen and (orientation: portrait) {
  .phone-rotation {
    display: block;
  }
}

.phone-rotation svg {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-container {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.close-container--white .close-ico {
  background-image: url("close-white.db5324ec.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 19px;
  display: inline-block;
}

.close-ico {
  background-image: url("close-black.754f982e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 19px;
  display: block;
}

.fullscreen-ico {
  background-image: url("fullscreen-black.96544b1d.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
  display: block;
}

.fullscreen-ico svg {
  fill: #878787;
  width: 15px;
  height: 15px;
}

.fullscreen-ico--white {
  background-image: url("fullscreen-white.a0f22d03.svg");
}

.fullscreen-ico--white svg {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.close-ico:hover {
  cursor: pointer;
}

.close-ico svg {
  fill: #878787;
  width: 18px;
  height: 18px;
}

.popup {
  position: relative;
}

.close-popup {
  background: none;
  border: none;
  border-radius: 0;
  transition: all .25s ease-in-out;
  position: absolute;
  top: 5px;
  right: 8px;
}

.close-popup:hover, .close-popup:focus {
  background: none;
  border: none;
  outline: none;
}

.popup__content {
  background: #000;
  border-radius: 20px;
  min-width: 85vw;
  height: 73vh;
  padding: 3rem;
  display: none;
  position: fixed;
  bottom: 20%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.popup__content--visible {
  display: block;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .popup__content {
    min-width: 85vw;
    height: 70vh;
    padding: 1.5rem 3rem;
    bottom: 22%;
  }
}

.popup__inner {
  height: 100%;
  overflow-y: scroll;
}

.credits__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.credits__list li {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}

.credits__list li:not(:last-child) {
  margin-bottom: 10px;
}

@media screen and (orientation: landscape) and (device-width <= 812px) {
  .credits__list li {
    font-size: 18px;
  }
}

.loader {
  background: none;
  border: 5px solid #0000;
  border-top-color: #5c5c5c;
  border-radius: 50%;
  width: 51px;
  height: 51px;
  margin: 0 auto;
  transition: all .3s ease-in-out;
  animation: .67s linear infinite rotate;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader.active {
  display: block;
  transform: translate(-50%, -50%);
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rooms-nav {
  background: #7fff00;
  flex-flow: row;
  padding: 3px;
  list-style-type: none;
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 40px;
}

.rooms-nav__link {
  color: purple;
  padding: 10px;
  font-size: 16px;
  text-decoration: underline;
}

.rooms-nav__link:hover {
  cursor: pointer;
}

.arrow-white {
  background-image: url("arrow.d311393c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.arrow-white--right {
  transform: rotate(90deg);
}

.arrow-white--left {
  transform: rotate(-90deg);
}

.arrow-white--bottom {
  transform: rotate(180deg);
}

.arrows-white {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.arrows-white .decrease-pov {
  width: 8px;
  height: 13px;
  display: block;
  transform: rotate(90deg);
}

.arrows-white .decrease-pov:hover {
  cursor: pointer;
}

.arrows-white .increase-pov {
  width: 8px;
  height: 13px;
  display: block;
  transform: rotate(-90deg);
}

.arrows-white .increase-pov:hover {
  cursor: pointer;
}

.stairs-icon {
  background-image: url("stairs.63eb776d.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 13px;
  height: 13px;
}

.informations-icon {
  background-image: url("informations.4903f34b.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.informations-icon--white {
  background-image: url("information-white.1e8b0858.svg");
}

.credits-icon {
  background-image: url("credits.827db735.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.credits-icon--white {
  background-image: url("credits-white.ae914980.svg");
}

.download-icon {
  background-image: url("download.c66f0cee.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.download-icon--white {
  background-image: url("download-white.f864a773.svg");
}

.label-room, .label-floor {
  pointer-events: none !important;
}
/*# sourceMappingURL=index.1804d8e8.css.map */
