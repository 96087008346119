.top-nav {
	position: absolute;
	font-family: "dm-sans-med";
	top: 0;
	left: 0;
	right: 0;
	width: 100vw;
	display: grid;
	grid-auto-flow: column;  
	grid-template-columns: 1fr 2fr 70px 70px 1fr 1fr 1fr;
  column-gap: 0;
  row-gap: 0;
}

@media screen and (orientation: landscape) and (max-device-width: 812px) {
  .top-nav {
    grid-template-columns: 1fr 2fr 70px 70px 0.5fr 0.5fr 0.5fr 0fr;
  }
}


.top-nav__item {
	color: $secondary-color;
	background: $primary-color;
	position: relative;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	padding: 8px 10px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	&--white {
		color: $primary-color;
		border: 1px solid currentColor;
		background: $secondary-color;
	}

	&--black {
		color: $secondary-color;
		border: 1px solid currentColor;
		background: $primary-color;
	}

	&--mobile-lg {
		@media screen and (orientation: landscape) and (max-device-width: 812px) {
			min-width: 45px;
			display: flex!important;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
  	}
	}

	&:first-child {
		border-right: 1px solid $secondary-color;
	}

	span:first-child {
		@media screen and (orientation: landscape) and (max-device-width: 812px) {
			margin-right: 10px;
		}
	}

	&:hover {
		cursor: pointer;
		//filter: contrast(0.75);
	}
}

.top-nav__sub {
	display: none;
	position: absolute;
	width: calc( 100% + 1px);
	margin: 0;
	top: 100%;
	left: 0;

	&--is-active {
		display: block;
	}

	.top-nav__item {
		justify-content: left;
		border: 1px solid currentColor;
		
		&:hover {
			background: $secondary-color;
			color: $primary-color;
			border: 1px solid currentColor;
			border-right: $primary-color;
			border-left: $primary-color;
		}

		&--white:hover {
			background: $primary-color;
			color: $secondary-color;
		}

		&--black:hover {
			background: $secondary-color;
			color: $primary-color;
		}
	}
}

.in-room-nav {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

//credits
.credits {
	margin: 0;
  display: inline-block;
	position: relative;
}

.credits__container {
	display: none;
	background: $primary-color;
	position: fixed;
	right: 0;
	top: 36px;
	margin: 0;
	height: calc(100vh - 36px);
	overflow-y: scroll;
	z-index: 1;

	&.is-active {
		display: block;
		z-index: 2;
	}
}

.credits__content {
	list-style-type: none;
	padding: 25px 40px 25px 25px;
	text-align: left;
	color: $secondary-color;
	margin: 0;
	font-size: 14px;
	font-family: "dm-sans-reg";
  	text-transform : initial;

	&:hover {
		cursor: auto;
	}
}

.credits__item {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.credits__text {
	margin: 0;
}

//site infos
.site-infos__content {
	display: none;
	position: fixed;
	overflow: auto;
	text-align: left;
	font-size: 14px;
	bottom: 0;
	right: 0;
	top: 36px;
	height: calc(100vh - 28px);
	width: auto; //defined in js
	padding: 25px;
	background: $secondary-color;
	color: $primary-color;
  	font-family: "dm-sans-reg";
  	text-transform : initial;
  	overflow: scroll;
	z-index: 1;

	&:hover {
		cursor: auto;
	}

	&.is-active {
		display: block;
		z-index: 2;
		border-left: 1px solid;
	}

	a {
		color: $primary-color!important;
		font-style: italic;
	}

	@media screen and (orientation: landscape) and (max-device-width: 812px) {
		padding: 15px 35px 15px 15px; 
	}
}

.site-infos__txt {
	padding-right: 20px;
}

//credits
.screenshot {
	margin: 0;
	position: relative;

	.blink-active {
		animation: blink 1s linear infinite;
	}
}

@keyframes blink{
	0%{opacity: 0;}
	20%{opacity: 0;}
	40%{opacity: .5;}
	65%{opacity: 1;}
	100%{opacity: 1;}
}

.screenshot__container {
	display: none;
	background: $secondary-color;
	position: fixed;
	right: 0;
	top: 36px;
	margin: 0;
	height: calc(100vh - 36px);
	overflow-y: scroll;
	z-index: 1;
	border-left:1px solid $primary-color;

	&.is-active {
		display: block;
		z-index: 2;
	}
}

.screenshot__content, .screenshot__content_nft {
	list-style-type: none;
	padding: 25px;
	text-align: left;
	color: $primary-color;
	//background: $secondary-color;
	//border-left:1px solid $primary-color;
	margin: 0;
	text-transform: initial;
	font-size: 14px;
	font-family: "dm-sans-reg";
  	text-transform : initial;

	&:hover {
		//cursor: pointer;
	}
}

.screenshot-valid {
	background: $primary-color;
    color: $secondary-color;
    padding: 8px;
    width: fit-content;
    text-transform: uppercase;
    &:hover {
		cursor: pointer;
		color:$primary-color;
		background: $secondary-color;
	}
	b {
		font-weight: 100;
		cursor: pointer;
	}
}


.screenshot__item {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.screenshot__text {
	margin: 0;
}

.lang-toggle {
	padding: 0;
	align-items: stretch;
	border-top: 1px solid $primary-color;
	border-bottom: 1px solid $primary-color;

	button {
		appearance: none;
		border: none;
		outline: none;
		background: transparent;
		padding: 0 15px;
		font-family: "dm-sans-med";
		font-size: 12px;
		text-transform: uppercase;

		&:first-child {
			color: $secondary-color;
			background: $primary-color;
		}
		&:last-child {
			color: $primary-color;
			background: $secondary-color;
			padding: 0 14px;
		}

		&:first-child::after {
			display: block;
			width: 5px;
			height: 10px;
			position: absolute;
			right: 0;
			top: 0;
		}
		&:focus {
			outline: 0;
		}
	}
	
}